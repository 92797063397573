import { post } from '@/utils/request'

//获取目录 物料类型列表
export function getScList(data) {
    return post('/edu/Tool/toolClassList', data)
}
//添加物料类型
export function addScType(data) {
    return post('/edu/Tool/addToolClass', data)
}
//物料类型回显
export function getInfo(data) {
    return post('/edu/Tool/toolClassInfo', data)
}

//物料类型编辑
export function editScType(data) {
    return post('/edu/Tool/editToolClass', data)
}
//物料类型删除
export function delScType(data) {
    return post('/edu/Tool/delToolClass', data)
}
// 不可拆分服务类型列表
export function noSplitTypeList(data) {
    return post('/edu/edu_type/getType0', data)
}


